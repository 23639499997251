import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ToolsPage.css';

const ToolsPage = () => {
  const [phoneForFacebook, setPhoneForFacebook] = useState('');
  const [phoneForInfo, setPhoneForInfo] = useState('');
  const [facebookLink, setFacebookLink] = useState('');
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingFb, setLoadingFb] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const showLoadingPhone = (show) => {
    setLoadingPhone(show);
  };

  const showLoadingFb = (show) => {
    setLoadingFb(show);
  };

  const showLoadingInfo = (show) => {
    setLoadingInfo(show);
  };

  const getFbId = async (facebookLink) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/get_fb_id`,
        { facebook_link: facebookLink },
        { headers: { token } }
      );
      if (response.status === 200 && response.data.id) {
        return { id: response.data.id, error: null };
      } else {
        return { id: null, error: response.data.message };
      }
    } catch (error) {
      return { id: null, error: 'Lỗi kết nối tới API' };
    }
  };

  const searchByPhone = async (e) => {
    e.preventDefault();
    showLoadingPhone(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/find_by_phone`,
        { phone: phoneForFacebook },
        { headers: { token } }
      );
      showLoadingPhone(false);
      if (response.status === 200) {
        setResult(response.data);
        setError('');
      } else {
        setError('Không tìm thấy kết quả');
      }
    } catch (error) {
      setError('Không tìm thấy kết quả');
      showLoadingPhone(false);
    }
  };

  const searchByFacebook = async (e) => {
    e.preventDefault();
    showLoadingFb(true);
    const { id, error } = await getFbId(facebookLink);
    if (id) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/find_by_id`,
          { id },
          { headers: { token } }
        );
        showLoadingFb(false);
        if (response.status === 200 && response.data) {
          setResult(response.data);
          setError('');
        } else {
          setError('Không tìm thấy kết quả');
        }
      } catch (error) {
        setError('Không tìm thấy kết quả');
        showLoadingFb(false);
      }
    } else {
      setError(`Lỗi: ${error}`);
      showLoadingFb(false);
    }
  };

  const checkInfo = async (e) => {
    e.preventDefault();
    showLoadingInfo(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/checkinfo?phone=${phoneForInfo}`);
      showLoadingInfo(false);
      if (response.status === 200) {
        navigate(`/info?phone=${phoneForInfo}`, { state: { info: response.data } });
      } else {
        setError('Không tìm thấy thông tin');
      }
    } catch (error) {
      setError('Không tìm thấy thông tin');
      showLoadingInfo(false);
    }
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  const renderResult = () => {
    if (result) {
      return (
        <div className="result-container">
          <div id="result-card" className="result-card">
            <div className="result-header">
              <h2 className="tit">KẾT QUẢ</h2>
              <button className="close-btn" onClick={() => setResult(null)}>X</button>
            </div>
            <hr />
            <div className="result-body">
              <p>
                <span className="label"><strong>Tên khách hàng:</strong></span>
                <span className="value">{result.name || 'Không có thông tin'}</span>
              </p>
              <p>
                <span className="label"><strong>Số điện thoại:</strong></span>
                <span className="value">
                  {result.phone ? `0${result.phone.slice(2)}` : 'Không có thông tin'}
                  {result.phone && (
                    <>
                      <a href={`tel:${result.phone}`} className="phone-icon">📞</a>
                      <button className="copy-btn" onClick={() => copyToClipboard(result.phone)}>📋</button>
                    </>
                  )}
                </span>
              </p>
              <p>
                <span className="label"><strong>Link Facebook:</strong></span>
                <span className="value">
                  {result.id ? (
                    <>
                      <a href={`https://www.facebook.com/${result.id}`} target="_blank" rel="noopener noreferrer">Mở link</a>
                      <button className="copy-btn" onClick={() => copyToClipboard(`https://www.facebook.com/${result.id}`)}>📋</button>
                    </>
                  ) : (
                    'Không có thông tin'
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="result-container">
          <div id="error-card" className="result-card">
            <div className="result-header">
              <h2>Lỗi</h2>
              <button className="close-btn" onClick={() => setError('')}>X</button>
            </div>
            <hr />
            <div className="result-body">
              <p>{error}</p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const toolCard = (title, inputLabel, buttonText, searchFunction, loading, inputValue, setInputValue) => (
    <div className="card">
      <h3>{title}</h3>
      <form onSubmit={searchFunction}>
        <div className="input-group">
          <label>{inputLabel}</label>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
          />
        </div>
        <button type="submit">{buttonText}</button>
        {loading && <div className="loading">Loading...</div>}
      </form>
    </div>
  );

  return (
    <div className="tools-page">
      <div className="tools-grid">
        {toolCard('Tìm FACEBOOK', 'Số điện thoại', 'Tìm kiếm', searchByPhone, loadingPhone, phoneForFacebook, setPhoneForFacebook)}
        {toolCard('Tìm SỐ ĐIỆN THOẠI', 'Link Facebook', 'Tìm kiếm', searchByFacebook, loadingFb, facebookLink, setFacebookLink)}
        {toolCard('Check Info', 'Số điện thoại', 'Check', checkInfo, loadingInfo, phoneForInfo, setPhoneForInfo)}
      </div>
      {renderResult()}
    </div>
  );
};

export default ToolsPage;
