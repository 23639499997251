import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaSignOutAlt } from 'react-icons/fa';
import './AppBar.css';

const AppBar = ({ onLogout }) => {
  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, {}, {
        headers: { 'token': token }
      });
    } catch (error) {
      console.error('Error logging out:', error);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    onLogout();
  };

  return (
    <header className="app-bar">
      <nav className="nav-center">
        <Link to="/tools" className="nav-link">Tra Cứu</Link>
      </nav>
      <nav className="nav-right">
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt />
        </button>
      </nav>
    </header>
  );
};

export default AppBar;
