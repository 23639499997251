import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const tryLogin = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        username: username,
        password: password,
      });
      if (response.status === 200) {
        const token = response.data.token;
        console.log(token);
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        onLogin(token);
        navigate('/tools'); // Điều hướng đến trang Tools sau khi đăng nhập thành công
      } else {
        setError('Đăng nhập thất bại!');
      }
    } catch (error) {
      setError('Đăng nhập thất bại!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    tryLogin();
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>KHÁCH NÉT . VIP</h2>
        <form onSubmit={handleSubmit}>
          {error && <p className="error">{error}</p>}
          <div className="input-group">
            <label htmlFor="username">Tên đăng nhập</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Đăng nhập</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
