// src/pages/SettingsPage.js
import React from 'react';
import './SettingsPage.css';

const SettingsPage = () => {
  return (
    <div className="settings-page">
      <h2>Settings Page</h2>
      {/* Nội dung của trang Settings */}
    </div>
  );
};

export default SettingsPage;
