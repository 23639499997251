import React from 'react';
import { useLocation } from 'react-router-dom';
import './InfoPage.css';

const InfoPage = () => {
  const location = useLocation();
  const info = location.state?.info;

  const renderFormattedData = (data) => {
    return Object.entries(data).map(([key, value]) => (
      <div key={key}>
        <strong>{key}:</strong> {value}
      </div>
    ));
  };

  return (
    <div className="info-page">
      <h2>Thông tin khách hàng</h2>
      {info ? (
        <div className="info-details">
          <div className="info-header">
            <p><strong>Tên khách hàng:</strong> {info.name}</p>
            <p><strong>Số điện thoại:</strong> {info.phone}</p>
            <p><strong>Dự án:</strong> {info.duan.join(', ')}</p>
          </div>
          <h3>Lịch sử</h3>
          <table className="history-table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Sheet Name</th>
                <th>File Name</th>
              </tr>
            </thead>
            <tbody>
              {info.history.map((record, index) => (
                <tr key={index}>
                  <td>{renderFormattedData(record.data)}</td>
                  <td>{record.sheet_name}</td>
                  <td>{record.file_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Không có thông tin khách hàng.</p>
      )}
    </div>
  );
};

export default InfoPage;
