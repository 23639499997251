import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import AppBar from './components/AppBar';
import ToolsPage from './pages/ToolsPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import InfoPage from './pages/InfoPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLogin = (token) => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <AppBar onLogout={handleLogout} />} {/* Pass handleLogout to AppBar */}
        <div className="content">
          <Routes>
            <Route path="/login" element={!isLoggedIn ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/" />} />
            <Route path="/tools" element={isLoggedIn ? <ToolsPage /> : <Navigate to="/login" />} />
            <Route path="/settings" element={isLoggedIn ? <SettingsPage /> : <Navigate to="/login" />} />
            <Route path="/info" element={isLoggedIn ? <InfoPage /> : <Navigate to="/login" />} /> {/* New route */}
            <Route path="/" element={isLoggedIn ? <ToolsPage /> : <Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
